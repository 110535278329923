import { Col, Form, Image, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Dragger from 'antd/lib/upload/Dragger';
import { useState } from 'react';
import Loader from '../../../components/Loader/Loader';
import { Button } from '../../../components/UI/Button/Button';
import { errorNotification } from '../../../components/UI/Toast/Toast';
import apiEndpoints from '../../../api/apiEndPoints';
import { getAccessToken } from '../../../utils/helper';
import Tooltip from '../../../components/UI/Tooltip/Tooltip';



const ClientBanner: React.FC = () => {
  const [form] = useForm(),
    [btnLoading, setBtnLoading] = useState<boolean>(false),
    [errorMessage, setErrorMessage] = useState(({} as any) || ''),
    [progress, setProgress] = useState(0),
    normFile = (e: any) => {
      if (Array.isArray(e)) {
        // console.log(e);
        return e;
      }
      return e?.fileList?.[0]?.response?.url;
    },
    normFile2 = (e: any) => {
      if (Array.isArray(e)) {
        // console.log(e);
        return e;
      }
      return e?.fileList?.[0]?.response?.url;
    },
    props = {
      name: 'image',
      accept: '.png, .svg,.jpg,.jpeg',
      multiple: false,
      action: apiEndpoints.imageUpload,
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
      },
      maxCount: 1,
      onChange(info: any) {
        const { status, progressPercent } = info.file;
        setProgress(progressPercent);
        if (info.file.status === 'done') {
          setProgress(100);
        } else if (status === 'error') {
          errorNotification(info?.file?.response?.error || 'Error : Please recheck the image and try again');
        }
      },
    },
    submitHandler = () => {
      form.validateFields().then((values) => {
        // updateValues(values);
      })
    };


  return (
    <Loader>
      <Form
        form={form}>
        <div className="title">Client Banner <Tooltip message={"This is the banner that will be available to the users on their app dashboard"} /> </div>
        <Row gutter={{ xl: 24, lg: 16, md: 16 }}>
          <Col className="gutter-row" span={14}>
            <Form.Item
              name={'logo_url'}
              valuePropName="image"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              validateTrigger={['onBlur']}
              getValueFromEvent={normFile}
              rules={[
                { required: true, message: "Please upload a logo URL for the client" },
              ]}
              label="Upload Image"
            >
              <Dragger {...props} listType="picture">
                <p className="ant-upload-drag-icon" style={{ marginTop: "24px" }}>
                  <Image src={form.getFieldValue('logo_url')} preview={false} height={96} />
                </p>
                <p className="ant-upload-text" style={{ marginBottom: "24px" }}>
                  Drag and drop your banner here
                  <br /> or click here to upload
                </p>
              </Dragger>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={14}>
            <Form.Item
              label="Redirect Link"
              labelCol={{ span: 24 }}
              name="redirect_link"
              validateFirst
              className="label margin-top"
              rules={[{ required: true, message: "Please enter redirect link!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt-24" gutter={16} justify="end">
          <Col>
            <Button type="primary" loading={btnLoading} onClick={() => submitHandler()}>Update</Button>
          </Col>
        </Row>
      </Form>
    </Loader>);
};

export default ClientBanner;
